import { FirstLoginCampaignStrategy } from "./types";

export class FirstLoginCampaign {
  public static cookieName = "__decodable_first_login_campaign_completed";
  constructor(private strategy?: FirstLoginCampaignStrategy) {}

  public init(): void {
    try {
      this.strategy.run();
    } catch (error) {
      console.error(error);
    }
  }

  public setStrategy(strategy: FirstLoginCampaignStrategy) {
    this.strategy = strategy;
  }
}
